import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ThemeProvider } from '@material-tailwind/react';
import { HomePage } from './pages/Home';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
export default App;

import React, { Ref, RefObject } from 'react';
type Props = { sectionRef: RefObject<any>; sectionName: string };

export const NavItem = ({ sectionRef, sectionName }: Props) => {
  return (
    <div
      key={`nav-${sectionName}`}
      className="cursor-pointer py-3 hover:border-b-1 hover:text-amber-900"
      onClick={() =>
        sectionRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    >
      {sectionName}
    </div>
  );
};

import React, { useRef } from 'react';
import { GallerySection } from '../components/GallerySection';
import { NavItem } from '../components/NavItem';

type Props = {};

export const HomePage = ({}: Props) => {
  const sectionNames = [
    'Entrances',
    'Timber Trusses',
    'Specialty Rooms',
    'Case Work',
    'Tables & Chairs',
  ];
  const sectionRefs = sectionNames.reduce((acc, sectionName) => {
    acc[sectionName] = useRef(null);
    return acc;
  }, {} as any);

  return (
    // <div className="flex flex-col h-screen">
    <div className="flex flex-col h-full fixed inset-0 overflow-hidden bg-#1c1b1b">
      <header className="flex sticky top-0 z-50 w-full align-center items-center justify-evenly shadow-md flex-wrap">
        {sectionNames.map((sectionName) => (
          <NavItem
            key={`key-${sectionName}`}
            sectionRef={sectionRefs[sectionName]}
            sectionName={sectionName}
          />
        ))}
      </header>

      <div className="flex-1 overflow-y-scroll no-scrollbar ">
        {sectionNames.map((sectionName) => (
          <GallerySection
            key={`gallery-${sectionName}`}
            sectionRef={sectionRefs[sectionName]}
            sectionName={sectionName}
          />
        ))}
      </div>
    </div>
  );
};

export type Section = {
  name: string;
  imageSections: string[][];
};

export const SECTION_DATA = [
  {
    name: 'Case Work',
    imageSections: [
      ['63'],
      ['22', '23', '24', '25'],
      ['76', '77'],
      ['70', '71', '72'],
    ],
  },
  {
    name: 'Tables & Chairs',
    imageSections: [
      ['119', '120', '118'],
      ['103', '65', '66'],
      ['105', '104'],
      ['97', '98', '99'],
      ['30', '31'],
      ['68', '69'],
      ['73', '74'],
      ['75'],
      ['108', '109', '110', '111'],
    ],
  },
  {
    name: 'Entrances',
    imageSections: [
      ['90', '38'],
      ['92', '93'],
      ['117', '116'],
    ],
  },
  {
    name: 'Timber Trusses',
    imageSections: [
      ['32', '33', '34', '35'],
      ['106', '107', '102', '101'],
    ],
  },
  {
    name: 'Specialty Rooms',
    imageSections: [
      ['40', '41', '42', '43', '44'],
      ['49', '50', '51', '52'],
      ['45', '46', '47', '48'],
      ['115', '112', '114', '113'],
      ['02', '03', '04', '05', '06', '07'],
      ['08', '09', '10', '11', '12', '13', '14'],
      ['15'],
      ['16', '17', '18', '19', '20', '21'],
      ['83', '84', '85', '86', '87', '88', '89'],
      ['26', '27', '28', '29'],
      ['53', '54', '55', '56', '57', '58', '59'],
      ['94', '95', '96'],
    ],
  },
] as Section[];

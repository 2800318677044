import React, { Ref, useEffect, useRef, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { SECTION_DATA, Section } from '../utils/constants';
// @ts-ignore
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

type Props = { sectionRef: Ref<any>; sectionName: string };

export const GallerySection = ({ sectionRef, sectionName }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [enableZoom, setEnableZoom] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Find the section with this name
  const section = SECTION_DATA.find((section) => section.name == sectionName);

  // Don't render if we don't find a matching section
  if (!section) {
    console.warn("Couldn't find section ", sectionName);
    return <></>;
  }

  const sectionImages = section?.imageSections.reduce(
    (acc, imageSection) => acc.concat(imageSection),
    []
  );

  const openLightbox = (image: string) => {
    const index = sectionImages.findIndex((value, _) => value == image, image);
    setCurrentImageIndex(index);
    setIsOpen(true);
  };
  console.log(currentImageIndex);

  const getSectionImage = (index: number) => {
    return sectionImages[(index + sectionImages.length) % sectionImages.length];
  };

  return (
    <div ref={sectionRef} className="flex flex-col w-full my-12 px-8">
      <div>
        <div className="text-center text-3xl w-full my-4">{section.name}</div>
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1028: 4, 1900: 5 }}
        >
          <Masonry gutter="18px">
            {section.imageSections.map((imageSection) => {
              return imageSection.map((image: string) => {
                return (
                  <img
                    className="cursor-pointer drop-shadow-[2px_2px_6px_rgba(0,12,12,1)] rounded-lg"
                    key={image}
                    src={`/pics/medium/${image}.jpg`}
                    style={{ width: '100%', display: 'block' }}
                    onClick={() => openLightbox(image)}
                  />
                );
              });
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
      {isOpen && (
        <Lightbox
          // ref={lightboxRef}
          mainSrc={`pics/medium/${getSectionImage(currentImageIndex)}.jpg`}
          nextSrc={`pics/medium/${getSectionImage(currentImageIndex + 1)}.jpg`}
          prevSrc={`pics/medium/${getSectionImage(currentImageIndex - 1)}.jpg`}
          enableZoom={enableZoom}
          onAfterOpen={() => {
            setEnableZoom(true);
          }}
          onCloseRequest={() => {
            setIsOpen(false);
            setEnableZoom(false);
          }}
          onMovePrevRequest={() =>
            setCurrentImageIndex(
              (currentImageIndex + sectionImages.length - 1) %
                sectionImages.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % sectionImages.length)
          }
        />
      )}
    </div>
  );
};
